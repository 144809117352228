import server from "./server";

export const getBlogsApi = async ({ cookies, page, limit, locale }) => {
  const response = await server({ cookies }).get(
    `/portal/blogs?page=${page}&limit=${limit}&locale=${locale}`
  );
  return response.data;
};
export const getBlogsByDateApi = async ({ cookies, date }) => {
  const response = await server({ cookies }).get(`/portal/blogs?date=${date}`);
  return response.data;
};
export const getBlogsByTagApi = async ({ cookies, slug }) => {
  const response = await server({ cookies }).get(
    `/portal/blogs/getBlogsByTag/${encodeURIComponent(slug)}`
  );
  return response.data;
};
export const getBlogsCategoriesApi = async ({ cookies }) => {
  const response = await server({ cookies }).get(`/portal/blogCategories`);
  return response.data;
};
export const getBlogsCategoryBySlugApi = async ({ cookies, slug }) => {
  const response = await server({ cookies }).get(
    `/portal/blogCategories/getCategoryBySlug/${encodeURIComponent(slug)}`
  );
  return response.data;
};
export const getSingleBlogApi = async ({ cookies, slug }) => {
  const response = await server({ cookies }).get(
    `/portal/blogs/getBlogBySlug/${encodeURIComponent(slug)}`
  );
  return response.data;
};
export const getBlogsTagsApi = async ({ cookies }) => {
  const response = await server({ cookies }).get(`/portal/blogs/getAllTag`);
  return response.data;
};
export const getBlogsArchive = async ({ cookies }) => {
  const response = await server({ cookies }).get(
    `/portal/blogs/getBlogsByDate`
  );
  return response.data;
};

export const addBlogReviewApi = async ({ cookies, data, id }) => {
  const response = await server({ cookies }).post(
    `/portal/blogs/addComment/${id}`,
    data
  );
  return response.data;
};
export const deleteBlogReviewApi = async ({ cookies, blogId, commentId }) => {
  const response = await server({ cookies }).delete(
    `/portal/blogs/deleteComment/${blogId}/${commentId}`
  );
  return response.data;
};
