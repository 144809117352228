import server from "./server";
// ================== Categories =================
export const getCategoriesApi = async ({ cookies }) => {
  const response = await server({ cookies }).get(`/categories`);
  return response.data;
};
export const getThemesApi = async ({ cookies }) => {
  const response = await server({ cookies }).get(`/themeTemplates`, {
    headers: {
      deviceType: "desktop",
    },
  });
  return response.data;
};
